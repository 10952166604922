import React from "react"
import { navigate } from "gatsby"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Table from "../components/table/table"
import ColumnContainer from "../components/columnContainer/columnContainer"
import ImageBg from "../components/imageBg/imageBg"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../../TC.pdf" title="Terms of use"/>;
const pcPdf = <IFrame file="../../PC.pdf" title="Privacy policy"/>;
// const footerDesc = "";
const contentMaxWidth = "980px";

const dataTable = {
  columns: ['','EVVA', "Banks"],
  rows: [
    {
      desc: "Online business account",
      evva: true,
      banks: false
    },
    {
      desc: "Business debit card",
      evva: true,
      banks: true
    },
    {
      desc: "Opening time",
      evva: "Minutes",
      banks: "2-4 weeks"
    },
    {
      desc: "Experience",
      evva: "100% digital",
      banks: "Long waits on branches"
    },
    {
      desc: "Personalized financial products",
      evva: true,
      banks: false
    },
    {
      desc: "Security controls from your phone",
      evva: true,
      banks: false
    },
    {
      desc: "Customer service",
      evva: "Personalized",
      banks: "Deficient"
    }
  ]
};

const IndexAlt = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Thank you for your interest!" subtitle="Access by invitation only, register to request your access." amplitude={appState.amplitude} amplitudeClickEvent="PreSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const goFinancingHandler = () => {
    navigate("/en/financing/")
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang}/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
      {!cookies.consent &&
      <OptOut text="This websites uses cookies to ensure that you get the best experience on our website." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="See Privacy Policy" cookieAction={cookieHandler}/>
      }
      <MainSection title="EVVA the digital business account for SMBs and Startups." description="<h3>Goodbye paperwork. With EVVA you open your business bank account in 10 minutes.</h3>" position="left" image="hero-home.png" actionLabel="Open an account" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
        <AppBar pathname={appState.path} />
      </MainSection>
      <PageSection>
        <div style={{marginTop: "-120px", zIndex: "1"}}>
          <Card
              subtitle="Get financing for your business"
              description="<p>We have joined forces with Uniclick by Unifin, leader in the financing market, which has funded thousands SMEs in México.</p>"
              descriptionColor="#363636"
              image="search-icon.svg"
              imageWidth="240px"
              imageHeight="160px"
              actionLabel="Learn more"
              action={goFinancingHandler}
              actionVariant="outlined"
              bgColor="#fff"
              horizontal
              padding
              shadow
          />
        </div>
      </PageSection>
      <PageSection maxColumns="2">
        <div style={{height: "510px"}}>
          <ImageBg width="80%" image="evva-features-phone.png" />
        </div>
        <ColumnContainer>
          <Card
              title="✔ For all sizes"
              description="<p>It doesn't matter if you're freelancer, small or medium business. Open your account on the day you ask it.</p>"
              padding
              shadow
          />
          <Card
              title="✔ 100% online"
              description="<p>Get on board from your phone and make any transaction. No paperwork or bank visiting needed.</p>"
              padding
              shadow
          />
          <Card
              title="✔ Secure"
              description="<p>Each movement from your collaborators needs to be approved. Avoid any diverted money.</p>"
              padding
              shadow
          />
        </ColumnContainer>
      </PageSection>
      <MainSection title="Split your personal expenses" description="<h3>You’ll be given a debit card exclusively for your business expenses.</h3>" position="left" image="evva-debit-card.jpg">
        <div style={{height: "100px"}} />
      </MainSection>
      <PageSection>
        <div style={{marginTop: "-160px", zIndex: "1"}}>
          <Table data={dataTable}/>
        </div>
      </PageSection>
      <PageSection title="With EVVA you are protected." description="The bank account is issued by our business partner a financing entity fully regulated by CONDUSEF and CNBV. Your account is protected by the IPAB Guaranteed Holders Program">
        <div style={{margin: "auto", maxWidth: contentMaxWidth}}>
          <img src="../../logos-protected.png" width="100%" alt="You are protected by CNBV, Condusef and IPAB"/>
        </div>
      </PageSection>
      <SecondarySection
        title="Together we grow"
        shortDescription="From Entrepreneurs to entrepreneurs, we are people like you who understand your needs. Our ultimate goal is to let you focus on what really matters ... Your business."
        position="center"
        image="evva-together.jpg"
        actionLabel="Open an account"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default IndexAlt